.ql-dark
  margin-bottom: 1.375rem

  .ql-snow
    border-radius: 5px 5px 0 0

    .ql-formats
      display: inherit

    .ql-formats:after
      display: none
      content: none

  .ql-container 
    height: auto
    border: none

  .ql-editor
    height: 150px 
    color: #ffffff
    background-color: #3a434e
    border: none
      radius: 0 0 5px 5px !important

    &::before
      color: rgba(255, 255, 255, 0.6)

    &::-webkit-scrollbar-thumb 
      background: rgba(white, 0.8)

    &::-webkit-scrollbar-track 
      background: rgba(white, 0.2)

  .ql-toolbar
    background-color: #3a434e 
    border: none
      bottom: 1px solid #7e7e82
    
    button, span
      background-color: transparent
      color: white !important

      .ql-stroke
        stroke: white !important

    .ql-picker
      background-color: #5d646b
      width: 105px !important
      height: auto !important
      margin:
        top: 5px
        right: 4px
      border-radius: 6px

      &-label
        color: white !important
        padding: 2px 5px

        &:before
          margin-right: 4px

        .ql-stroke
          stroke: white !important

    .ql-fill
      fill: white !important

    .ql-align
      width: 28px !important
      height: 24px !important
      margin: 0 !important
      background-color: transparent !important

      .ql-picker-label
        padding: 0px 5px

      .ql-picker-options
        background-color: #3a434e !important
    
    .ql-formats
      padding-left: 0px
      padding-right: 0px
      margin-left: 0px
