.ql-editor
  min-height: 5.625rem
  display: block
  width: 100%
  padding: 0.5rem 0.75rem
  font-size: 0.9375rem
  line-height: 1.5
  color: #12263F
  background-color: #FFFFFF
  background-clip: padding-box
  // border: 1px solid #D2DDEC
  border-radius: 0.375rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out