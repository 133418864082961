// .rdw-editor-wrapper
//   margin:
//     bottom: 20px

//   .rdw-editor-main
//     background-color: #ffffff

//   .rdw-option-wrapper
//     border: none

//     img
      // width: 11px 
.ql-editor
  border-radius: 0
.ql-container.ql-snow
  box-shadow: 0px 3px 20px #00000008
  border: 1px solid #0000000F
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px
  border-top: 0

.product-icon-wysiwyg #icons.quill .ql-container.ql-snow .ql-editor img
  height: 14px
  width: 14px

.career-page-block-content
  #wysiwyg-editor, #text-picture-wysiwyg-editor
    position: relative

    .ql-tooltip.ql-editing
      left: -23px !important

  #text-picture-wysiwyg-editor .ql-tooltip.ql-editing
    margin-top: -35px !important

  #wysiwyg-editor .ql-tooltip.ql-editing
    margin-top: -48px !important

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options
    background-color: #5d646b

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg
  position: absolute
  margin-top: -9px
  right: -12px
  top: 50%
  width: 18px

.ql-snow .ql-picker-label
  cursor: pointer
  display: inline-block
  height: 100%
  /* padding-left: 8px; */
  padding-right: 2px
  position: relative
  width: 100%

.ql-toolbar.ql-snow
  padding: 12px 8px 12px
  background: #F5F6F7 0% 0% no-repeat padding-box
  box-shadow: 0px 3px 20px #00000008
  border: 1px solid #BFBFBF40
  border-radius: 4px 4px 0px 0px

.tooltip.show.bs-tooltip-auto
  display: none!important

.alignment .ql-picker-label
  margin: 0

.ql-toolbar.ql-snow .ql-formats
  margin-right: 12px

.ql-snow.is-invalid .ql-toolbar.ql-snow, .ql-snow.is-invalid .ql-container.ql-snow
  border-color: #d32f2f!important
