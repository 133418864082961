@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/cerebrisans/cerebrisans-regular.eot");
  src: url("./assets/cerebrisans/cerebrisans-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/cerebrisans/cerebrisans-regular.woff") format("woff"),
    url("./assets/cerebrisans/cerebrisans-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/cerebrisans/cerebrisans-medium.eot");
  src: url("./assets/cerebrisans/cerebrisans-medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/cerebrisans/cerebrisans-medium.woff") format("woff"),
    url("./assets/cerebrisans/cerebrisans-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/cerebrisans/cerebrisans-semibold.eot");
  src: url("./assets/cerebrisans/cerebrisans-semibold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/cerebrisans/cerebrisans-semibold.woff") format("woff"),
    url("./assets/cerebrisans/cerebrisans-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans Pro";
  src: url("./assets/cerebrisanspro/CerebriSansPro-Regular.woff") format("woff"),
    url("./assets/cerebrisanspro/CerebriSansPro-Regular.ttf") format("truetype"),
    url("./assets/cerebrisanspro/CerebriSansPro-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans Pro";
  src: url("./assets/cerebrisanspro/CerebriSansPro-Medium.woff") format("woff"),
    url("./assets/cerebrisanspro/CerebriSansPro-Medium.ttf") format("truetype"),
    url("./assets/cerebrisanspro/CerebriSansPro-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans Pro";
  src: url("./assets/cerebrisanspro/CerebriSansPro-SemiBold.woff")
      format("woff"),
    url("./assets/cerebrisanspro/CerebriSansPro-SemiBold.ttf")
      format("truetype"),
    url("./assets/cerebrisanspro/CerebriSansPro-SemiBold.otf")
      format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans Pro";
  src: url("./assets/cerebrisanspro/CerebriSansPro-Bold.woff") format("woff"),
    url("./assets/cerebrisanspro/CerebriSansPro-Bold.ttf") format("truetype"),
    url("./assets/cerebrisanspro/CerebriSansPro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
